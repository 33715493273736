import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Slider1 from '../Elements/Slider1';
import WhatWeDo1 from '../Elements/WhatWeDo1';
import Testimonials1 from '../Elements/Testimonials1';
import Services1 from '../Elements/Services1';
import ClientsLogo1 from '../Elements/ClientsLogo1';
import Blog1 from '../Elements/Blog1';
import About3 from '../Elements/About3';
import Projects2 from '../Elements/Projects2';


class Home1 extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider1 />
                    <About3 />
                    <WhatWeDo1 />
                    <Testimonials1 />
                    <Services1 />
                    <Projects2 />
                    <ClientsLogo1 />
                    <Blog1 />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home1;