import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';

const bgimg1 = require('./../../images/video-bg.jpg');
const bgimg2 = require('./../../images/background/bg-4.png'); // Add missing bgimg2 import

class About3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b80 bg-white bg-no-repeat bg-bottom-left" >
                    <div className="container ">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                                <div className="sx-separator bg-white bg-moving bg-repeat-x" 
                                     style={{ backgroundImage: `url(${bgimg2})` }}>
                                    <h3 className="sep-line-one">About us</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className={`section-full mobile-page-padding p-t10 p-b50 ${this.props.bgcolor || ''}`}>
                            <div className="container">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="about-home-3 m-b30 bg-white">
                                                <h3 className="m-t0 m-b20 sx-title">Your Vision, Our Passion, Every Detail Perfected</h3>
                                                <p>At Roushni Interiors, we don’t just create designs; we craft experiences. Based in the vibrant city of Bangalore, our passion lies in transforming spaces into stories that reflect individuality and purpose. Whether it’s a serene home, a bustling office, or a robust industrial space, we approach every project with fresh eyes and a commitment to excellence.</p>
                                                <ul className="list-angle-right anchor-line">
                                                    <li><NavLink to="#">We provide a architectural 3D modeling services.</NavLink></li>
                                                    <li><NavLink to="#">Our specialists are ready to consult you on any topic.</NavLink></li>
                                                    <li><NavLink to="#">We develop and implement better interior design.</NavLink></li>
                                                    <li><NavLink to="#">We provide high-quality interior services for clients.</NavLink></li>
                                                </ul>
                                                <div className="text-left">
                                                    <NavLink to="#" className="site-button btn-half">
                                                        <span>Read More</span>
                                                    </NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-12">
                                            <div className="video-section-full-v2">
                                                <div className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30" 
                                                     style={{ backgroundImage: `url(${bgimg1})` }}>
                                                    <div className="overlay-main bg-black opacity-04" />
                                                    <div className="video-section-inner">
                                                        <div className="video-section-content">
                                                            <NavLink to="#" className="play-now" data-toggle="modal" data-target="#myModal">
                                                                <i className="icon fa fa-play" />
                                                                <span className="ripple" />
                                                            </NavLink>
                                                            <div className="video-section-bottom">
                                                                <h3 className="sx-title text-white">
                                                                    25 Years<br />Experience
                                                                </h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Video Modal */}
                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://vimeo.com/' />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default About3;
