import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/mobilenav.js');

    };

    render() {
        return (
            <>
                <ul className="nav navbar-nav">
                    <li className="active">
                    <NavLink to={"/"}>Home</NavLink>
                    </li>
                    <li><NavLink to={""}>About us</NavLink></li>
                    <li>
                        <NavLink to={""}>Services</NavLink>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to={""}>Residential</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={""}>Living Room</NavLink></li>
                                    <li><NavLink to={""}>Bedroom</NavLink></li>
                                    <li><NavLink to={""}>Modular Kitchen</NavLink></li>
                                    <li><NavLink to={""}>Kid's Room</NavLink></li>
                                    <li><NavLink to={""}>Home Remodeling</NavLink></li>
                                    <li><NavLink to={""}>Villa's Interior</NavLink></li>
                                    <li><NavLink to={""}>Apartments Interior</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={""}>Commercial</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={""}>Office Interiors</NavLink></li>
                                    <li><NavLink to={""}>Retail Space Design</NavLink></li>
                                    <li><NavLink to={""}>Hospital & Healthcare</NavLink></li>
                                    <li><NavLink to={""}>Educational Institutions</NavLink></li>
                                    <li><NavLink to={""}>Co-Working Spaces</NavLink></li>
                                </ul>
                            </li>
                            <li>
                                <NavLink to={""}>Industrial</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={""}>Warehouse Design</NavLink></li>
                                    <li><NavLink to={""}>Factory Interiors</NavLink></li>
                                    <li><NavLink to={""}>Restaurant Design</NavLink></li>
                                    <li><NavLink to={""}>Industrial Office Spaces</NavLink></li>
                                    <li><NavLink to={""}>Showroom Interiors</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to={""}>Furniture Redisng</NavLink></li>
                            <li><NavLink to={""}>Vastu Consultation</NavLink></li>
                            <li><NavLink to={""}>Project Management</NavLink></li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to={""}>Projects</NavLink>
                        <ul className="sub-menu">
                            <li><NavLink to={""}>Residential</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={""}>Bedroom</NavLink></li>
                                    <li><NavLink to={""}>Bathroom</NavLink></li>
                                    <li><NavLink to={""}>Living room</NavLink></li>
                                    <li><NavLink to={""}>Modular Kitchen</NavLink></li>
                                    <li><NavLink to={""}>Home Exterior</NavLink></li>
                                    <li><NavLink to={""}>Home Interior</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to={""}>Commercial</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={""}>Offices</NavLink></li>
                                    <li><NavLink to={""}>Showrooms</NavLink></li>
                                    <li><NavLink to={""}>Retail Spaces</NavLink></li>
                                    <li><NavLink to={""}>Hospitals</NavLink></li>
                                    <li><NavLink to={""}>Educational Institutions</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to={""}>Industrial</NavLink>
                                <ul className="sub-menu">
                                    <li><NavLink to={""}>Warehouse</NavLink></li>
                                    <li><NavLink to={""}>Restaurants</NavLink></li>
                                    <li><NavLink to={""}>Industrial Kicthens</NavLink></li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li><NavLink to={""}>Blogs</NavLink></li>
                    <li><NavLink to={""}>Help & Support</NavLink></li>
                    <li><NavLink to={"/contact-us"}>Contact us</NavLink></li>
                </ul>
            </>
        );
    };
};

export default Navigation;