import React from 'react';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/pic1.jpg'),
        reviewername: 'Arun Kumar',
        Location: 'Bangalore, India',
        review: '“Roushni Interiors transformed my old apartment into a modern masterpiece. Their expertise in residential interior design in Bangalore is unmatched. Every detail was meticulously planned and executed. Highly recommend their services!.”'
    },
    {
        image: require('./../../images/testimonials/pic2.jpg'),
        reviewername: 'Deepa Reddy',
        Location: 'Hyderabad',
        review: '“I needed someone who understood my vision for a cozy yet functional office space. Roushni Interiors delivered exactly that. If you’re looking for office interior designers in Hyderabad, they’re the best choice!”'
    },
    {
        image: require('./../../images/testimonials/pic3.jpg'),
        reviewername: 'Karthik Shetty',
        Location: 'Mysore',
        review: '“The team at Roushni Interiors revamped our living room with such elegance and precision. Their attention to detail in home interiors near Bangalore is simply outstanding and the design concepts are top-notch!”'
    },
    {
        image: require('./../../images/testimonials/pic4.jpg'),
        reviewername: 'Priya Nair',
        Location: 'Chennai',
        review: '“Roushni Interiors helped me create a stunning yet functional kitchen space. Their work on residential interiors in South India truly stands out and I highly recommend them for any interior design project of any kind!”'
    },
    {
        image: require('./../../images/testimonials/pic3.jpg'),
        reviewername: 'Mohammed Ghouse',
        Location: 'Coimbatore',
        review: '“Our restaurant’s new look is stunning, thanks to Roushni Interiors. They are exceptional at hospitality design services near Bangalore! I highly recommend roushni interiors for restaurant interior set up and fit out services”'
    },
]

var bgimg1 = require('./../../images/background/bg-8.jpeg');
var bgimg2 = require('./../../images/background/bg-5.png');
var bgimg3 = require('./../../images/background/cross-line2.png');
var bgimg4 = require('./../../images/background/bg-12.jpg');

class Testimonials1 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            autoplayTimeout: 6000,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 1
                }
            }
        };
        return (
            <>
                <div className="section-full container-fluid">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 bg-gray bg-cover bg-no-repeat" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                <div className="sx-left-part mobile-page-padding">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="sx-separator-outer separator-left">
                                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg3 + ')' }}>
                                                <h3 className="sep-line-one">Why Choose Us?</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    <p>We bring your vision to life with tailored designs that blend creativity and functionality. With expert craftsmanship and end-to-end project management, we ensure every space is transformed with precision and care.</p>
                                    <div className="counter-blocks">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count  text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={19} duration={5} /></span></h2>
                                                    <h4 className="m-tb0">Years of Experience</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={170} duration={5} />+</span></h2>
                                                    <h4 className="m-tb0">Projects Completed</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={100} duration={5} />%</span></h2>
                                                    <h4 className="m-tb0">Client Satisfaction</h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                                                <div className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-white p-a30" style={{ backgroundImage: 'url(' + bgimg2 + ')' }}>
                                                    <h2 className="st-count-number sx-text-primary text-left"><span className="counter"><CountUp end={28} duration={5} /></span><span>+</span></h2>
                                                    <h4 className="m-tb0">Brands Collobration</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 bg-white bg-repeat" style={{ backgroundImage: 'url(' + bgimg4 + ')' }}>
                                <div className="sx-right-part mobile-page-padding  p-t80 p-b50">
                                    {/* TITLE START */}
                                    <div className="section-head">
                                        <div className="sx-separator-outer separator-left">
                                            <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg3 + ')' }}>
                                                <h3 className="sep-line-one">Testimonial</h3>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TITLE END */}
                                    {/* TESTIMONIAL START */}
                                    <OwlCarousel className="owl-carousel testimonial-home number-slider" {...options}>
                                        {testimonials.map((item, index) => (
                                            <div className="item" key={index}>
                                                <div className="testimonial-2  hover-animation-1">
                                                    <div className="testimonial-detail clearfix">
                                                        <div className="testimonial-pic shadow scale-in-center"><img src={item.image} alt="" width={100} height={100} /></div>
                                                        <h4 className="testimonial-name">{item.reviewername}</h4>
                                                        <span className="testimonial-position">{item.position}</span>
                                                        <span className="fa fa-quote-right" />
                                                    </div>
                                                    <div className="testimonial-text bg-white  shadow-sm">
                                                        <p>{item.review}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}

                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials1;